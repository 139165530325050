@use "../../styles/mixin.scss" as m;
@import "../../styles/variable.scss";

.mainSection {
	margin: 3em 1.5em;
}

h1 {
	@include m.title("BBBPoppins-Display", 700, normal, 3em, unset); // 48px
}
h2,
.customContact {
	@include m.title("BBBPoppins-Display", 400, normal, 2.25em, unset); // 36px
}

a {
	text-decoration: unset;
	color: $black_color;
	&:hover {
		text-decoration: underline;
	}
}

.job {
	color: $black_color;
	font-family: "BBBPoppins-Display";
	font-weight: 700;
	font-style: normal;
	margin: 1em 0;
}

.info {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.identity {
	flex: 3;
}

.logo {
	width: 100%;
	flex: 1;
}

img {
	max-width: 20em;
	height: auto;
	contain: content;
	min-width: 1em;
}

.workshop {
	margin: 40px 0 80px 0;
	width: 100%;
	border-top: 2.5px solid $black_color;
	h3 {
		@include m.title("BBBPoppins-Display", 400, normal, 1.875em, unset);

		background-color: $primary_color;
		padding: 0.313em;
		text-align: center;
		border-radius: 30px;
		max-width: 8em;
		margin-top: 2em;
	}
}
.infoWorkshop {
	padding-top: 1.5em;
	.titleWorkshop {
		font-weight: 700;
	}
	p {
		color: $black_color;
		font-family: "LS-VG5000";
		font-weight: 400;
		font-size: 1.875em;
		font-style: normal;
		margin: unset;
	}
	.worshopRegistration {
		padding-top: 0.5em;
	}
}
.mendatoryIndication > p {
	margin: unset;
	color: $black_color;
	font-family: "BBBPoppins-Display";
	font-weight: 400;
	font-size: 0.5em;
	font-style: normal;
	.websiteFont {
		font-size: 1em;
	}
}

@media screen and (max-width: 863px) {
	h1 {
		@include m.title("BBBPoppins-Display", 700, normal, 2.5em, unset);
	}
	h2,
	.customContact {
		@include m.title("BBBPoppins-Display", 400, normal, 1.5em, unset);
	}
	.job {
		margin: 1em 0 2em 0;
	}
	.logo {
		display: flex;
		justify-content: center;
	}

	.workshop {
		margin: 3em 0;
		h3 {
			align-items: center;
			@include m.title("BBBPoppins-Display", 400, normal, 1.5em, unset);
			margin-top: 2em;
		}

		p,
		.worshopRegistration {
			font-family: "LS-VG5000";
			font-size: 1.5em;
			font-weight: 400;
			font-style: normal;
		}
		.contactWorkshop {
			font-family: "LS-VG5000";
			font-size: 1em;
			font-weight: 400;
			font-style: normal;
		}
	}
}

@media screen and (max-width: 535px) {
	h1 {
		@include m.title("BBBPoppins-Display", 700, normal, 2em, unset);
	}
	h2,
	.customContact {
		@include m.title("BBBPoppins-Display", 400, normal, 1.2em, unset);
	}
	.job {
		margin: 1em 0 2em 0;
	}
	.logo {
		display: flex;
		justify-content: center;
	}

	.workshop {
		margin: 3em 0;
		h3 {
			align-items: center;
			@include m.title("BBBPoppins-Display", 400, normal, 1.2em, unset);
			margin-top: 2em;
		}
		.infoWorkshop {
			.titleWorkshop {
				font-size: 1.2em;
			}
			p {
				font-size: 1.2em;
			}
			.worshopRegistration {
				padding-top: 0.5em;
			}
		}
		p,
		.worshopRegistration {
			font-family: "LS-VG5000";
			font-size: 1.5em;
			font-weight: 400;
			font-style: normal;
		}
		.contactWorkshop {
			font-family: "LS-VG5000";
			font-size: 1em;
			font-weight: 400;
			font-style: normal;
		}
	}

	.mendatoryIndication > p {
		font-size: 0.25em;
		.websiteFont {
			font-size: 0.25em;
		}
	}
}
